import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '@solid-ui-blocks/Hero/Block01'
import Content from '@solid-ui-blocks/Content/Block01'
import Footer from '@solid-ui-blocks/Footer/Block01'
import Team from '@solid-ui-blocks/Teams/Block02'
import Services from '@solid-ui-blocks/FeaturesWithPhoto/Block02'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import styles from './_styles'

const Privacy01 = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout {...props}>
      <Seo title='Privacidad' />
      {/* Modals */}
      <ModalWithTabs content={content['contact']} />
      {/* Blocks */}
      <Header content={content['header-light']} />
      <Container variant='full' sx={styles.heroContainer} styles={{paddingTop:0,marginTop:0}}>
        <Container variant='cards.paper-lg' sx={styles.servicesContainer} styles={{paddingTop:0,marginTop:0}}>
          <h2>PRIVACIDAD</h2>
          <p style={{fontSize:14}}>Bienvenido a Cardinal (en conjunto con las aplicaciones mobile).</p>
          <p style={{fontSize:14}}>Cardinal respeta su derecho de privacidad. Esta política resume qué información personal podemos recoger, de qué manera podemos utilizar esta información y otros temas importantes relacionados con su privacidad y protección de datos.</p>
          <p style={{fontSize:14}}>Es política de Cardinal cumplir con todas las leyes de privacidad y de protección de datos vigentes. Este compromiso refleja el valor que le damos al hecho de obtener y conservar la confianza de nuestros clientes, socios comerciales y demás personas que comparten su información personal con nosotros.</p>
          <p style={{fontSize:14}}>Esta Política se aplica a todos los Sitios de Internet y aplicaciones móviles administrados por Cardinal o en su nombre, e incluye las compañias de Cardinal.dev de todo el mundo (conocidas como “Sitio de Internet o Aplicación Móvil de Cardinal”). También se aplica a toda la información personal que Cardinal pueda recoger de cualquier otro modo: (i) a través de nuestros productos y servicios; (ii) cuando interactúa con nosotros por medios distintos de un Sitio de Internet o Aplicación Móvil, por ejemplo, de manera presencial, por teléfono o en formación; y (iii) a través de nuestros clientes, distribuidores, proveedores, vendedores y otros socios comerciales (en conjunto, “Socios Comerciales”).</p>
          <p style={{fontSize:14}}>Principios generales de recogida, uso y divulgación de información personal. En la medida que así lo requiera la legislación vigente, siempre que Cardinal recoja información personal, Cardinal:</p>
      <ul>
        <li style={{fontSize:14}}>le notificará de forma oportuna y adecuada el uso de sus datos por parte de Cardinal;</li>
        <li style={{fontSize:14}}>recogerá su información personal sólo para fines específicos y legítimos. La información que recojamos será pertinente, adecuada y no excesiva para los fines para los que se recoja;</li>
        <li style={{fontSize:14}}>tratará su información personal de manera consistente con los fines para los cuales se recogió originalmente o para los que usted otorgó posteriormente su consentimiento;</li>
        <li style={{fontSize:14}}>adoptará las medidas comerciales razonables para garantizar que su información personal sea fiable, exacta y completa para el uso previsto y, cuando corresponda, actualizada;</li>
        <li style={{fontSize:14}}>no utilizará su información personal para el envío de comunicaciones comerciales de forma directa sin darle la oportunidad de “decir que no”; y adoptará las medidas adecuadas, por contrato o de cualquier otra forma, para garantizar una protección adecuada de la información personal que se divulgue a terceros o se transfiera a otro país.</li>
      </ul>
      <h6 style={{fontSize:14}}>Información personal recogida por Cardinal</h6>
      <p style={{fontSize:14}}>Cardinal recoge los siguientes tipos de información personal:</p>
      <p style={{fontSize:14}}>Información proporcionada por usted: Cardinal recoge la información personal que usted nos proporciona, como por ejemplo: (i) información de contacto, como su nombre, nombre de empresa, puesto de trabajo, dirección postal, dirección de correo electrónico y número de teléfono; (ii) información adicional acerca de usted para ayudarnos a conocerle mejor, como su sexo, edad, fecha de nacimiento, nacionalidad, asociaciones profesionales y sus números de registro, comentarios, preguntas, solicitudes y pedidos que desee realizar; (iii) información de inicio de sesión, incluyendo, si procede, información de cuentas de redes sociales para fines de inicio de sesión; e (iv) información acerca de sus preferencias, como sus métodos preferidos de comunicación; (v) información gps sobre las rutas realizadas cuando se encuentra en viaje de entrega.</p>
        <p style={{fontSize:14}}>Información recogida automáticamente de su equipo; Información sobre el equipo y el navegador. Cardinal puede recoger información técnica sobre su equipo, tal y como el tipo de equipo, tipo de navegador, dirección IP, sistema operativo e identificador del equipo. Cardinal recoge automáticamente esta información de su equipo y navegador web mediante cookies y tecnologías similares. Información sobre el modo en que se relaciona con nosotros. Cardinal puede recoger datos técnicos sobre el uso que hace de los Sitios de Internet y Aplicaciones Móviles de Cardinal. Información sobre su ubicación. Cardinal puede recoger información sobre su ubicación, tal y como información precisa y en tiempo real sobre su ubicación a partir de su equipo e información imprecisa sobre la ubicación a partir de, por ejemplo, su dirección IP o código postal. Las aplicación de Chofer accede a información precisa y en tiempo real sobre la ubicación. A partir de que el mismo se encuentre logueado en la APP con su usuario provisto por la logística.</p>
       <p style={{fontSize:14}}>Cookies y tecnologías similares: Una “cookie” es un archivo de información que se instala en su equipo cuando visita un sitio web. Las cookies y tecnologías similares pueden mejorar su experiencia de usuario al guardar sus preferencias, personalizar su experiencia online, almacenar artículos en su carrito de compras y, en algunas ocasiones, proporcionarle anuncios publicitarios adaptados a sus intereses.</p>
       <h6 style={{fontSize:14}}>Más Información</h6>
       <p style={{fontSize:14}}>Los Sitios de Internet de Cardinal usan “cookies de sesión”. Una cookie de sesión no le identifica de forma individual y caduca cuando se cierra el explorador.</p>
       <p style={{fontSize:14}}>Los Sitios de Internet de Cardinal también usan “cookies persistentes”. Estas cookies no caducan cuando se cierra el navegador. Las cookies persistentes permanecen en su ordenador hasta que usted las elimina o caduquen. Al asignarle un identificador exclusivo a su ordenador, podemos crear una base de datos de sus preferencias y elecciones anteriores que se pueden proporcionar automáticamente, lo que le ahorra tiempo y trabajo en futuras visitas. Por ejemplo, después de hacer una compra, si decide hacer otra compra, es posible que su dirección de envío se haya conservado y solo necesite confirmarla.</p>
       <p style={{fontSize:14}}>Algunos países requieren que otorgue su consentimiento para el uso de cookies cuando visita Sitios de Internet de Cardinal. Si accede a un Sitio de Internet de Cardinal de alguno de estos países, se le mostrará información acerca de las opciones que tiene, incluyendo la opción de aceptar o rechazar determinadas categorías de cookies.</p>
       <p style={{fontSize:14}}>Si bien no tiene la obligación de aceptar cookies cuando visita un Sitio de Internet de Cardinal, es posible que no pueda utilizar todas las funciones del sitio si ha rechazado determinadas cookies.</p>
       <p style={{fontSize:14}}>Además, su navegador puede permitirle ajustar la configuración para aceptar o rechazar cookies, o enviarle alertas cuando una cookie se instale en su equipo.</p>
       <h6 style={{fontSize:14}}>Cómo utiliza Cardinal su información personal</h6>
       <p style={{fontSize:14}}>Cardinal puede usar su información personal para:</p>
       <ul>
         <li style={{fontSize:14}}>Desarrollar y administrar la relación que mantenemos con usted y nuestros Socios Comerciales. Esto puede incluir: (i) prestar servicios o efectuar operaciones que usted o nuestros Socios Comerciales hayan solicitado; (ii) proporcionar información acerca de productos, servicios y operaciones de Cardinal; (iii) ofrecerle a usted y a nuestros Socios Comerciales una experiencia más uniforme durante las interacciones con Cardinal, por ejemplo, mediante un conocimiento más detallado del modo en que usa e interactúa con los Sitios de Internet, Aplicaciones Móviles, productos y servicios; y (iv) planificar, administrar y operar con arreglo a la relación contractual que mantenemos con nuestros Socios Comerciales.</li>
       <li style={{fontSize:14}}>Comunicarse con usted o su empresa. Esto puede incluir: (i) informarle de productos, servicios y actividades promocionales de Cardinal que puedan interesarle a usted o a su empresa; (ii) ofrecer información acerca de productos, servicios y operaciones pertinentes de Cardinal, por ejemplo, información sobre la fijación de precios; datos técnicos; información sobre facturas, envíos o producción, información sobre garantías; o información sobre mejoras de productos o servicios; (iii) responder a preguntas o consultas que nos plantee, como solicitudes de atención al cliente; e (iv) invitarle a participar en encuestas de satisfacción del cliente o estudios de mercado o informarle de los resultados de los mismos.</li>
       <li style={{fontSize:14}}>Ofrecer y mejorar nuestros Sitios de Internet, Aplicaciones Móviles, productos y servicios. Esto puede incluir: (i) adecuarlos a sus preferencias o intereses, hacerlos más compatibles con su tecnología o ampliar la facilidad de uso de cualquier otra manera; (ii) mantener la seguridad y protegerlos de otra forma; y (iii) desarrollar nuevos Sitios de Internet, Aplicaciones Móviles, productos y servicios de Cardinal.</li>
       <li style={{fontSize:14}}>Abordar cuestiones legales. Esto puede incluir: (i) cumplir con nuestras obligaciones de conservar determinados registros comerciales durante períodos de conservación mínimos; (ii) establecer, ejercer o defender derechos legales; (iii) cumplir con leyes, normas, órdenes judiciales u otros procesos legales; (iv) detectar, prevenir y responder a fraudes, vulneraciones de la propiedad intelectual,o industrial, incumplimiento de nuestros contratos o acuerdos, incumplimiento de la ley o cualquier otro uso indebido de los Sitios de Internet, Aplicaciones Móviles, productos o servicios de Cardinal; y (iv) proteger los derechos o propiedad de Cardinal, o la salud, seguridad, bienestar, derechos o propiedad de usted u otros.</li>
       </ul>
       <p style={{fontSize:14}}>Cardinal también puede utilizar su información personal para otros usos consistentes con el contexto en que la información se recogió con su consentimiento.</p>
       <p style={{fontSize:14}}>Cardinal puede anonimizar o agregar cualquier información recogida y usarla para cualquier fin, como, por ejemplo, investigar y desarrollar nuevos productos. Esta información no servirá para identificarle personalmente.</p>
       </Container>
      </Container>
     {/* <Divider space='5' />
      <Team content={content['team']} />*/}
      <Divider space='5' />
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query innerpagePrivacidad {
    allBlockContent(
      filter: { page: { in: ["site/privacidad", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default Privacy01
